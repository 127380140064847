.model-content {
    border-color: aquamarine;
}

.upload-image-file {
    border: 1px dashed #ccc;
    display: inline-block;
    padding: 6px 25px;
    color: #6A67CE;
    cursor: pointer;
}

.test-file {
    cursor: pointer;
}

.nav-hr-team-page:hover {
    color: rgb(61, 119, 189);
}

.border-page {
    border: 1px solid black;
    width: 100%;
    height: 50px;
    padding: 10px;
    margin: -5px 15px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.border-team-page {
    border: 1px solid black;
    width: 100%;
    height: 50px;
    padding: 10px;
    margin: 0px 15px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.remove-team-page {
    color: rgb(1, 2, 1);
}

.file-delete-team-page {
    font-size: 20px;
}

.remove-team-page:hover {
    color: rgb(230, 37, 37);
}

.upload-image-team-file {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 25px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    cursor: pointer;
}

.custom-tooltip {
    width: 140px;
    height: 60px;
    border: 1px solid cornflowerblue;
    overflow: hidden;
}

.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.custom-tooltip p:first-of-type {
    font-weight: bold;
}

.btn_submit_page:hover {
    color: cornsilk;
}

/* .rotate:hover {
    animation: loading 3s linear infinite;
} */

@keyframes loading {
    from {
        transform: rotate(300deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.rmsc .gray {
    --rmsc-bg: #fff !important;
    background-color: #fff !important;
}

.rmsc .dropdown-content {
    z-index: 99999 !important;
}

.notification-icon:hover {
    color: #0d0d0e;
    font-size: large;
    background-color: rgb(104, 182, 238) !important;
}

.stage-log_page:hover {
    background-color: darkcyan;
    font-size: large;
    transition: all .5s ease-in-out;
    color: antiquewhite;
}

.modal-backdrop.show {
    opacity: 0.8 !important;
}

.button_btn_size {
    font-weight: 500;
    font-size: '1.0rem';
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.table-size-reduce {
    font-family: Helvetica;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.table-span-plus {
    height: 14px;
    width: 14px;
    display: inline-block;
    position: relative;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 15px;
    background-color: #31b131;
    cursor: pointer;
}

.table-span-minus {
    height: 14px;
    width: 14px;
    display: inline-block;
    position: relative;
    color: white;
    border: 2px solid white;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 15px;
    cursor: pointer;
    background-color: #d33333;
}

li.loyalty_font:hover {
    font-size: large;
    border: 1px double #000;
    color: gray;
    cursor: pointer;
    border-color: #6A67CE;
}

.card_text_change:hover {
    font-size: large;
    color: gray;
}

.delete-msg:hover {
    font-size: large;
    color: red;
}


/* table css */
@media (2230px <=width <=2550px) {

    .priority-17,
    .priority-16 {
        display: none;
    }
}

@media (1920px <=width <=2230px) {

    .priority-17,
    .priority-16,
    .priority-15 {
        display: none;
    }
}

@media (1707px <=width <=1920px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13 {
        display: none;
    }
}

@media (1620px <=width <=1707px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12 {
        display: none;
    }
}

@media (1500px <=width <=1620px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11 {
        display: none;
    }
}

@media (1276px <=width <=1500px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9 {
        display: none;
    }
}

@media (1080px <=width <=1276px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9,
    .priority-8 {
        display: none;
    }
}

@media (720px <=width <=1080px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9,
    .priority-8,
    .priority-7 {
        display: none;
    }
}

@media (565px <=width <=720px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9,
    .priority-8,
    .priority-7,
    .priority-6 {
        display: none;
    }
}

@media (400px <=width <=565px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9,
    .priority-8,
    .priority-7,
    .priority-6,
    .priority-5 {
        display: none;
    }
}

@media (350px <=width <=400px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9,
    .priority-8,
    .priority-7,
    .priority-6,
    .priority-5,
    .priority-4 {
        display: none;
    }
}

@media (300px <=width <=350px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9,
    .priority-8,
    .priority-7,
    .priority-6,
    .priority-5,
    .priority-4,
    .priority-3 {
        display: none;
    }
}

@media (200px <=width <=300px) {

    .priority-17,
    .priority-16,
    .priority-15,
    .priority-14,
    .priority-13,
    .priority-12,
    .priority-11,
    .priority-10,
    .priority-9,
    .priority-8,
    .priority-7,
    .priority-6,
    .priority-5,
    .priority-4,
    .priority-3,
    .priority-2,
    .priority-1 {
        display: none;
    }
}

.text-change-size:hover {
    font-size: 17px;
    background-color: aquamarine;
    border-left: 2px solid rgb(79, 230, 79);
}

.table {
    margin-left: 0px;
}

.face-icon {
    animation: shake 10s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 2000px;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.btn_color_change:hover {
    background-color: #688bed;
    color: #fff;
}

.btn_color_change {
    background-color: #663399;
    color: #fff;
}
.checkbox-control{
    font-family: system-ui, sans-serif;
    font-size: 0.8rem;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
  }
  .toast_remove:hover{
    color: #ff0;
  }
  .fc-title{
    color: #fff;
  }
  .ql-editor {
    color: black !important;
  }
  .li_tag_hover:hover{
    text-decoration: underline!important;
    color: #3156a6!important;
  }
  .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root{
    z-index: 1050 !important;
  }
  .modal-close-button::after {
    content: '×'; /* Cross icon character */
    font-size: 25px;
    font-weight: bold;
}